$(document).ready(function() {
    // $("#orientLayer").css({
    //     display: "none"
    // });
    window.onorientationchange = reorient;
})

function reorient(e) {
    var portrait = (window.orientation % 180 == 0);
    if ($(wondiw).width() < 1200) {
        if (!portrait)
            $("#orientLayer").css({
                display: "block"
            });
        else
            $("#orientLayer").css({
                display: "none"
            });
    }
}

function clickSection(e) {
    $("#fp-nav ul li:nth-child(" + e + ") a").trigger('click');
}